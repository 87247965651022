export default {
  getApplications: (state) => state.allApps,
  getPagination: (state) => state.pagination,
  getAllStatuses: (state) => state.allStatuses,
  getCurrentApplication: (state) => state.currentApplication,
  getResaleCertificates: (state) => state.resaleCertificates,
  getApplicationProductFormData: (state) => state.applicationProductFormData,
  getApplicationFormDeliveryErrorMessage: (state) => state.applicationFormDeliveryErrorMessage,
  getApplicationDeletedProductFormData: (state) => state.applicationDeletedProductFormData,
  getApplicationDeliveryFeeFormData: (state) => state.applicationDeliveryFeeFormData,
  getCanUserExtendEpo: (state) => state.canUserExtendEpo,
  getEpoExtensionData: (state) => state.epoExtensionData
}
