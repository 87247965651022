export default {
  allApps: [],
  pagination: {
    current_page: 1,
    page_count: 1,
    item_count: 0
  },
  currentApplication: {},
  allStatuses: [],
  saveChangesPrompt: false,
  resaleCertificates: [],
  // for contract creation steps
  applicationProductFormData: [], // used to hold form input data when signing lease
  applicationFormDeliveryErrorMessage: '',
  applicationDeletedProductFormData: [],
  applicationDeliveryFeeFormData: 0,
  canUserExtendEpo: false,
  epoExtensionData: null
}
