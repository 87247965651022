export const FEATURE_FLAGS = {
  SFC_PROMO: 'financial-connection-promotion',
  FAST_APP: 'fast-app-merchant-client',
  CHIME_STORE: 'chime-enabled-stores',
  CO_APPLICANT: 'co-applicant-test',
  QR_MODAL: 'qr-application-modal',
  FAST_APP_RETURN_CUSTOMER: 'merchant-fast-app-returning-customer',
  LEADS: 'kafene-leads',
  HIGHERAA: 'higher-aa-treatment-options',
  SHOW_LEASE_SUMMARY: 'show-lease-summary',
  LEASE_SIGNING_TOKEN_AUTOFILL: 'lease-signing-token-autofill',
  BOOST_MOBILE_EXPERIENCE: 'boost-mobile-experience'
}

export const SFC_PROMO_VARIATIONS = {
  MERCHANT: 'merchant',
  MERCHANT_CUSTOMER: 'merchant customer'
}
export const SOP_TREATMENT = {
  AUTOMATIC: 'automatic increase',
  OPTIN: 'opt-in'
}
export const FAST_APP_VARIATIONS = {
  FAST_APP: 'fast-app_no-prove'
}

export const CHIME_ROUTING_NUMBERS = ['031101279', '103100195']

export const APPLICATION_RESPONSE_ERRORS = {
  SAVINGS_ACCOUNT: 'savings_account_error',
  ADDRESS: 'address_error'
}

export const HIGHER_AA_OPTIONS = {
  TEN_DOLLAR_INCREASES: '10-doll-increases',
  FIFTY_DOLLAR_INCREASES: '50-doll-increases'
}

export const GOOGLE_API_KEY = 'AIzaSyC69tJjX9OOqxiTdMeN4AnEaq6w3nLgNDE'

export const HIGHER_AA_RESTRICTED_STATES = ['CA', 'CO']
export const MAX_APPROVAL_AMOUNT = 5000
export const MINIMUM_INVOICE_TOTAL = 300
export const MINIMUM_BOOST_MOBILE_INVOICE_TOTAL = 149
export const MAX_BOOST_MOBILE_PRODUCTS = 3
